import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/HomePage/First/First";
import Second from "../../components/HomePage/Second/Second";
import Three from "../../components/HomePage/Three/Three";
import Four from "../../components/HomePage/Four/Four";
import Five from "../../components/HomePage/Five/Five";
import Six from "../../components/HomePage/Six/Six";
import Seven from "../../components/HomePage/Seven/Seven";
import Eight from "../../components/HomePage/Eight/Eight";
import Nine from "../../components/HomePage/Nine/Nine";
import Ten from "../../components/HomePage/Ten/Ten";
import Eleven from "../../components/HomePage/Eleven/Eleven";
import Popup from "../../components/GlobalComponents/Popup/Popup";
import OurTraders from "../../components/HomePage/OurTraders/OurTraders";
import Slider from "../../components/HomePage/Slider/Slider";
import SecondFooter from "../../components/GlobalComponents/SecondFooter/SecondFooter";
import OurAdvantages from "../../components/HomePage/OurAdvantages/OurAdvantages";

const HomePage = () => {
  return (
    <HelmetProvider>
      <div className="ovf-hidden">
        <Helmet>
          <title>Tradify</title>
          <meta
            name="title"
            content="Trade with Confidence on Tradify Trading Platform | Join Now"
          />
          <meta
            property="og:title"
            content="Trade with Confidence on Tradify Trading Platform | Join Now"
          />
          <meta
            property="twitter:title"
            content="Trade with Confidence on Tradify Trading Platform | Join Now"
          />

          <meta
            name="description"
            content="Join Tradify, the premier trading platform for trading a wide range of financial instruments with confidence. Enjoy advanced trading tools, expert support, and educational resources to help you achieve your trading goals."
          />
          <meta
            property="og:description"
            content="Join Tradify, the premier trading platform for trading a wide range of financial instruments with confidence. Enjoy advanced trading tools, expert support, and educational resources to help you achieve your trading goals."
          />
          <meta
            property="twitter:description"
            content="Join Tradify, the premier trading platform for trading a wide range of financial instruments with confidence. Enjoy advanced trading tools, expert support, and educational resources to help you achieve your trading goals."
          />

          <meta property="og:image" content="preview.png" />
          <meta property="og:type" content="website" />
          <meta property="twitter:image" content="preview.png" />
          <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
        <div className="pageBg"></div>
        <div className="content">
          <Header />

          <Popup />
          <First />
          <OurAdvantages />
          <Second />
          <Three />
          <Four />
          <Five />
          <Six />
          <Seven />
          <Eight />
          <Nine />
          <Ten />
          <Eleven />

          <Footer />
          <Slider />
          <SecondFooter />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
