import logo from '../assets/img/logo.png';
import bg from '../assets/img/page_bg.png';
// home 


export const images = [
    logo,
    bg,

];
