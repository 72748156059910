import { useEffect, useState, useRef } from "react";
import classes from "./Ten.module.scss";
import img1 from "../../../assets/img/HomePage/10/1.png";
import img2 from "../../../assets/img/HomePage/10/2.png";
import img3 from "../../../assets/img/HomePage/10/3.png";
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Ten = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.second, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div
              className={[classes.secondRowLeft, ""].join(" ")}
              ref={monetRef2}
            >
              <div className={[classes.secondRowLeftAbsImg, ""].join(" ")}>
                <img src={img3} alt="Reviews" />
              </div>

              <div className={[classes.secondRowLeftImg, ""].join(" ")}>
                <img src={img1} alt="Reviews" />
              </div>

              <h2 className={[classes.secondRowLeftTitle, "font-64"].join(" ")}>
                Completing tasks on the Internet:
              </h2>
              <p className={[classes.addTitle, "font-20", "mb20"].join(" ")}>
                Outsource tasks such as data entry, research, and more to our
                team of experts.
              </p>
              <p
                className={[classes.secondRowLeftText, "font-24 mb40"].join(
                  " "
                )}
              >
                Get top results with us now!
              </p>
              <div className={[classes.secondRowLeftBtn, ""].join(" ")}>
                <Button secondary="true">Start Now! </Button>
              </div>
            </div>
            <div
              className={[classes.secondRowRight, "monet24"].join(" ")}
              ref={monetRef1}
            >
              <img src={img2} alt="Reviews" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ten;
