import { useEffect, useState, useRef } from "react";
import classes from "./Eleven.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/HomePage/11/1.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const Eleven = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.eleven, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.elevenBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.elevenRow, ""].join(" ")}>
            <div
              className={[classes.elevenRowLeft, ""].join(" ")}
              ref={monetRef2}
            >
              <h3 className={[classes.elevenRowLeftTitle, "font-64"].join(" ")}>
                Our Values
              </h3>
              <p className={[classes.elevenRowLeftText, "font-24"].join(" ")}>
                Our team of experts is dedicated to providing you with the
                highest quality service, and our platform is built to ensure
                that you get the results you need. Whether you're looking to
                increase your online visibility, boost your sales, or gain a
                competitive edge, we have the tools and expertise to help you
                achieve your goals.
              </p>
              <p className={[classes.elevenRowLeftText, "font-24"].join(" ")}>
                Don't waste any more time and effort trying to do it all on your
                own. Sign up for Work Up Club today and start seeing real
                results. With our easy-to-use platform and top-notch customer
                support, you can trust that we have you covered.
              </p>
              <div className={[classes.elevenRowLeftBtn, ""].join(" ")}>
                <Button>Start Now! </Button>
              </div>
            </div>
            <div
              className={[classes.elevenRowRight, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img1} alt="Our Values" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Eleven;
