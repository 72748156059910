import { useEffect, useState, useRef } from "react";
import classes from "./OurAdvantages.module.scss";
import img1 from "../../../assets/img/HomePage/advantages/1.png";
import img2 from "../../../assets/img/HomePage/advantages/2.png";
import img3 from "../../../assets/img/HomePage/advantages/3.png";
import img4 from "../../../assets/img/HomePage/advantages/4.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const OurAdvantages = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);
  const monetRef3 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top center",
          end: "top top",
          scrub: 1,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top center",
          end: "top top",
          scrub: 2,
        },
        y: 0,
      });
      gsap.timeline().to(monetRef3.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top center",
          end: "top top",
          scrub: 3,
        },
        y: 0,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.our, ""].join(" ")} ref={col1}>
      <div className={[classes.ourBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.ourTitle, "font-64-main"].join(" ")}>
            Our Advantages:
          </div>

          <div className={[classes.ourRow, ""].join(" ")}>
            <div
              className={[classes.ourRowColumn, ""].join(" ")}
              ref={monetRef1}
            >
              <div className={[classes.ourRowColumnImg, ""].join(" ")}>
                <img src={img1} alt="" />
              </div>
              <h3 className={[classes.ourRowColumnTitle, "font-40"].join(" ")}>
                Cost-effective
              </h3>
              <p className={[classes.ourRowColumnText, "font-20"].join(" ")}>
                One of the advantages of working with Work Up Club is that it
                can be a cost-effective solution for businesses of all sizes.
                Rather than hiring a full-time marketing team, which can be
                expensive, clients can work with Work Up Club on a
                project-by-project basis, allowing them to save money on
                overhead costs.
              </p>
            </div>
            <div
              className={[classes.ourRowColumn, ""].join(" ")}
              ref={monetRef2}
            >
              <div className={[classes.ourRowColumnImg, ""].join(" ")}>
                <img src={img2} alt="" />
              </div>
              <h3 className={[classes.ourRowColumnTitle, "font-40"].join(" ")}>
                Scalability
              </h3>
              <p className={[classes.ourRowColumnText, "font-20"].join(" ")}>
                Work Up Club can scale its services up or down based on a
                client's needs. Whether a client needs help with a small project
                or a comprehensive marketing campaign, the platform can provide
                the necessary support and resources to get the job done.
              </p>
            </div>
            <div
              className={[classes.ourRowColumn, ""].join(" ")}
              ref={monetRef3}
            >
              <div className={[classes.ourRowColumnImg, ""].join(" ")}>
                <img src={img3} alt="" />
              </div>
              <h3 className={[classes.ourRowColumnTitle, "font-40"].join(" ")}>
                Time-saving
              </h3>
              <p className={[classes.ourRowColumnText, "font-20"].join(" ")}>
                By outsourcing marketing tasks to Work Up Club, clients can save
                time and focus on other important aspects of their business.
                This can be especially beneficial for small businesses and
                marketing beginners with limited resources.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurAdvantages;
