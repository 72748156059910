import { useEffect, useState, useRef } from "react";
import classes from "./Seven.module.scss";
import img1 from "../../../assets/img/HomePage/7/1.png";
import img2 from "../../../assets/img/HomePage/7/2.png";
// import img3 from "../../../assets/img/HomePage/3/3.png";
import Button from "../../GlobalComponents/Button/Button";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Seven = () => {
  gsap.config({
    force3D: true,
  });

  const [width, setWidth] = useState();
  const col1 = useRef(null);
  const monetRef1 = useRef(null);
  const monetRef2 = useRef(null);

  useEffect(() => {
    if (width > 1220) {
      gsap.timeline().to(monetRef2.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        x: 0,
        autoAlpha: 1,
      });
      gsap.timeline().to(monetRef1.current, {
        scrollTrigger: {
          trigger: col1.current,
          start: "top bottom",
          end: "top top",
          scrub: 2,
        },
        y: 0,
        autoAlpha: 1,
      });
    }
  }, [width]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", (event) => {
      setWidth(window.innerWidth);
    });
  }, []);
  return (
    <div className={[classes.three, "mb100"].join(" ")} ref={col1}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div
              className={[classes.threeRowRight, ""].join(" ")}
              ref={monetRef1}
            >
              <img src={img2} alt="Reviews" />
            </div>
            <div
              className={[classes.threeRowLeft, ""].join(" ")}
              ref={monetRef2}
            >
              {/* <div className={[classes.threeRowLeftAbsImg, ""].join(" ")}>
                <img src={img3} alt="Reviews" />
              </div> */}

              <div className={[classes.threeRowLeftImg, ""].join(" ")}>
                <img src={img1} alt="Reviews" />
              </div>

              <h2 className={[classes.threeRowLeftTitle, "font-64"].join(" ")}>
                Getting a lot of downloads for your application
              </h2>
              <p className={[classes.addTitle, "font-20", "mb20"].join(" ")}>
                Increase your app downloads and boost your visibility in the app
                store.
              </p>
              <p
                className={[classes.secondRowLeftText, "font-24 mb40"].join(
                  " "
                )}
              >
                Increase your audience activity with us!
              </p>
              <div className={[classes.threeRowLeftBtn, ""].join(" ")}>
                <Button secondary="true">Start Now! </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Seven;
