import React, { useEffect } from "react";
import img1 from "../../../assets/img/HomePage/slider/1.png";
import img2 from "../../../assets/img/HomePage/slider/2.png";
import img3 from "../../../assets/img/HomePage/slider/3.png";
import classes from "./Slider.module.scss";
import imgLeft from "../../../assets/img/HomePage/slider/left.png";
import imgRight from "../../../assets/img/HomePage/slider/right.png";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
// import { Fab } from "@material-ui/core";
// import testlefticon from "../assets/pictures/testlefticon.svg"
// import testrightiicon from "../assets/pictures/testrightiicon.svg"

import "./styles.scss";
import { Slide } from "./testimonial.js";
// import pic1 from "../assets/pictures/our-clients/pic1.png"
// import pic2 from "../assets/pictures/our-clients/pic2.png"
// import pic3 from "../assets/pictures/our-clients/pic3.png"
// import pic4 from "../assets/pictures/our-clients/pic4.png"

const data = [
  {
    image: img1,
    text: '"Work Up Club has been a game-changer for my client’s business. As I was a marketer of a small startup, I didn\'t have the resources to build an in-house marketing team, but I knew that I needed to invest in marketing efforts to grow. Work Up Club provided the perfect solution, offering a cost-effective way to outsource our marketing needs . Bussines has seen a significant increase in website traffic, leads, and sales since partnering with Work Up Club, and I’ve excited to continue working with them as the bussines grow."',
    name: "Mark",
    star: 90,
    subname: "online - business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img2,
    text: '"I was hesitant to work with a marketing agency at first, but after hearing about Work Up Club from a colleague, I decided to give them a try. I was blown away by their level of expertise and the customized approach they took to our marketing needs. They helped me to revamp my client’s website, optimize social media channels, and launch a successful email marketing campaign that drove significant revenue for the business. I\'m grateful to have found Work Up Club and would recommend them to anyone looking for a trusted marketing partner."',
    name: "Kate",
    star: 95,
    subname: "website’s advertiser",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
  {
    image: img3,
    text: "\"Work Up Club has been an absolute pleasure to work with. They've helped me to streamline my marketing efforts and focus on the tactics that will drive the greatest ROI for business. I appreciate their attention to detail and the fact that they always keep us in the loop on what's happening with my campaigns. I highly recommend Work Up Club to anyone looking for a top-notch marketing agency.\"",
    name: "Sam",
    star: 85,
    subname: "business marketer",
    colorStars: classes.tradersSliderSlideItemTopStarsProgress5,
  },
];

const Testimonial = () => {
  const ref = React.useRef(StackedCarousel);
  useEffect(() => {
    // setInterval(stuff, 6000);
    // console.log("i fire once");
  }, []);

  function stuff() {
    ref.current?.goNext();
  }

  return (
    <div className={[classes.slider, ""].join(" ")}>
      <h3 className={[classes.title, "font-64"].join(" ")}>
        Reviews From Our Customers:
      </h3>

      <div className="card card-carrier">
        <div style={{ position: "relative" }}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(width, carouselRef) => {
              let currentVisibleSlide = 3;
              if (width <= 1280) currentVisibleSlide = 3;
              if (width <= 720) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Slide}
                  slideWidth={width > 1650 ? 1074 : width > 1220 ? 750 : 500}
                  carouselWidth={width}
                  data={data}
                  maxVisibleSlide={3}
                  customScales={[
                    1,
                    width > 1220 ? 0.55 : 0.65,
                    width > 1900
                      ? -0.19
                      : width > 1850
                      ? -0.21
                      : width > 1800
                      ? -0.23
                      : width > 1750
                      ? -0.25
                      : width > 1700
                      ? -0.27
                      : width > 1650
                      ? -0.29
                      : width > 1600
                      ? -0.02
                      : width > 1550
                      ? -0.05
                      : width > 1500
                      ? -0.07
                      : width > 1450
                      ? -0.1
                      : width > 1400
                      ? -0.13
                      : width > 1350
                      ? -0.17
                      : width > 1300
                      ? -0.2
                      : width > 1250
                      ? -0.23
                      : width > 1220
                      ? -0.26
                      : width > 1150
                      ? 0.7
                      : width > 1100
                      ? 0.5
                      : width > 1050
                      ? 0.4
                      : width > 1000
                      ? 0.4
                      : width > 950
                      ? 0.4
                      : width > 900
                      ? 0.2
                      : 0.2,
                  ]}
                  transitionTime={450}
                  currentVisibleSlide={currentVisibleSlide}
                />
              );
            }}
          />
          <div className={[classes.arrows, ""].join(" ")}>
            <div
              className={[classes.arrowsLeft, ""].join(" ")}
              onClick={() => ref.current?.goBack()}
            >
              <img src={imgLeft} alt="" />
            </div>
            <div
              className={[classes.arrowsRight, ""].join(" ")}
              onClick={() => ref.current?.goNext()}
            >
              <img src={imgRight} alt="" />
            </div>
          </div>

          {/* <div
          className="card-button testimonial-left-button"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          left
        </div>
        <div
          className="card-button testimonial-right-button"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          right
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
