import React from "react";
import classes from "./SecondFooter.module.scss";
import logo from "../../../assets/img/logo.png";
const SecondFooter = () => {
  return (
    <div className={[classes.footer, ""].join(" ")}>
      <div className={[classes.footerBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.row, ""].join(" ")}>
            <div className={[classes.rowLogo, ""].join(" ")}>
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondFooter;
