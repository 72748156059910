import HomePage from '../pages/HomePage/HomePage';


export const routes = [
    { path: '*', element: HomePage },
    // { path: '/for-traders', element: ForTradersPage },
    // { path: '/promotions', element: PromotionsPage },
    // { path: '/account-types', element: AccountTypesPage },
    // { path: '/faq', element: FaqPage },
    // { path: '/about', element: AboutPage },
    // { path: '/:lang/', element: HomePage },
    // { path: '/:lang/for-traders', element: ForTradersPage },
    // { path: '/:lang/promotions', element: PromotionsPage },
    // { path: '/:lang/account-types', element: AccountTypesPage },
    // { path: '/:lang/faq', element: FaqPage },
    // { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: 'for-traders', text: 'For Traders' },
    { id: 2, path: 'promotions', text: 'Promotions' },
    { id: 3, path: 'account-types', text: 'Account Types' },
    { id: 4, path: 'faq', text: 'FAQ' },
    { id: 5, path: 'about', text: 'About Us' },
];
