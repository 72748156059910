import React from "react";
import classes from "./Footer.module.scss";

import bg from "../../../assets/img/footer_bg.png";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.footer, ""].join(" ")}>
      <div className={[classes.footerBody, ""].join(" ")}>
        <div className={[classes.footerImg, ""].join(" ")}>
          <img src={bg} alt="" />
        </div>

        <div className="container">
          <div className={[classes.footerRow, ""].join(" ")}>
            <div className={[classes.footerRowLogo, ""].join(" ")}>
              {/* <img src={logo} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
